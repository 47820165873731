import React from "react"
import Layout from "../components/Layout"
import * as styles from "../styles/about.module.css"
import Family from "../../static/images/family.jpg"
import Linkedin from "../../static/images/linkedin.png"
import Behance from "../../static/images/behance.png"
import Email from "../../static/images/email.png"
import CVimage from "../../static/images/cv.png"
import Resume from "../../static/downloads/Joe_Resume_2022.pdf"

export default function About() {
  return (
    <Layout>
      <section className={styles.header}>
        <div className={styles.family}>
          <img src={Family} height="600px" width="auto" alt="logo"></img>
        </div>
        <div className={styles.mainText}>
          <h3>
            Hi, I'm <span className={styles.joe}>Joe</span>!
          </h3>
          <p className={styles.p}>
            I love to design and create things. Whether it's a web-app or a
            motion graphic video, I enjoy the process of problem solving how the
            user or viewer will best understand the message that is intended to
            be conveyed.
          </p>
          <p className={styles.p}>
            When I am not developing or designing, I enjoy spending time with my
            family and friends. As well as playing sports, going out to take
            photos, learning new ways of web development, like new libraries,
            frameworks, and learning deeper things about the God that I love.
          </p>
          <p className={styles.p}>
            I have been blessed with a wonderful wife and 2 children.
            <br />
            <br />I am currently employed by Stanford University's IT
            Applications Team developing and maintaining over 30+ applications
            as a full-stack developer.
          </p>
          <div className={styles.socials}>
            <p>Contact Me:</p>
            <div className={styles.socialsIcon}>
              <a
                href="https://www.linkedin.com/in/joechung76/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Linkedin}
                  height="35px"
                  width="auto"
                  alt="linkedin"
                ></img>
              </a>
              <a
                href="https://www.behance.net/joechung76"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Behance}
                  height="35px"
                  width="auto"
                  alt="behance"
                ></img>
              </a>
              <a
                href="mailto:joechung76@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Email} height="35px" width="auto" alt="email"></img>
              </a>
              <a href={Resume} download>
                <img
                  src={CVimage}
                  height="35px"
                  width="auto"
                  alt="resume"
                ></img>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
